import React from "react";
import {
  SectionContainer,
  OfferingTextContent
} from ".";

export const OfferingsSection = props => {
  const {
    header,
    subtitle,
    detail,
    navActionUrl,
    navAction,
    imgSrc,
    flipOrder,
    exampleDescription
  } = props;

  const textContent = <OfferingTextContent
    header={header}
    subtitle={subtitle}
    detail={detail}
    navActionUrl={navActionUrl}
    navAction={navAction} />;

  const imageContent = (
    <div className='flex flex-col items-center'>
      {imgSrc ? (<div className='mt-4 md:mx-4 rounded shadow-lg bg-white'>
        <img className='lg:max-w-lg rounded' src={imgSrc} alt={header} />
      </div>) : null}
      {exampleDescription ? (
        <div className='text-sm italic mt-2'>
          <span>{exampleDescription}</span>
        </div>
      ) : null}
    </div>
  );

  return (
    <div className='my-12 md:my-24 md:px-4 lg:px-12'>
      <SectionContainer className='hidden sm:flex lg:h-screen-1/4 items-center'>
        {flipOrder ? (
          <>
            {imageContent}
            {textContent}
          </>
        ) : (
            <>
              {textContent}
              {imageContent}
            </>
          )}
      </SectionContainer>
      <SectionContainer className='flex sm:hidden'>
        {textContent}
        {imageContent}
      </SectionContainer>
    </div>
  );
};
