import React from "react";
import {
    SectionHeader,
    SectionSubtitle,
    SectionDetail,
    SectionNavAction
} from ".";
import { Dash } from "../symbols/Dash";

export const OfferingTextContent = props => {

    const {
        header,
        subtitle,
        detail,
        navActionUrl,
        navAction,
    } = props;

    return (
        <div className='flex-basis-3/4 sm:flex-basis-1 lg:flex-basis-3/4 mx-4 sm:mx-0'>
            <SectionHeader>
                {header}
                <Dash />
            </SectionHeader>
            <SectionSubtitle>
                <span>{subtitle}</span>
            </SectionSubtitle>
            <SectionDetail>
                <span>{detail}</span>
            </SectionDetail>
            {navAction ? (<SectionNavAction>
                <a href={navActionUrl}>{navAction}</a>
            </SectionNavAction>) : null}
        </div>
    );
};