import React from "react";
import Layout from "./Layout";
import { menuItems } from "../services/constants";
import { ContactUsSection } from "./ContactUsSection";

export default props => {
  const { titleComponent, hideContactUs, wipWiderLayout, helmetTitle } = props;

  return (
    <Layout bgColorClass='bg-transparent' menuItems={menuItems} helmetTitle={helmetTitle} isServiceLayout={true}>
      <div className={`my-6 sm:mx-4 sm:mx-auto h-screen-9/10 sm:max-w-2xl ${wipWiderLayout ? 'lg:max-w-6xl' : 'lg:max-w-3xl'}`}>
        {titleComponent}
        {props.children}
        <div className="mt-12">
          {hideContactUs ? null : <ContactUsSection />}
        </div>
      </div>
    </Layout>
  );
};
