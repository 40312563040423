import React from "react";
import { graphql } from "gatsby";
import Img from "react-cloudinary-lazy-image";
import ServiceLayout from "../../components/ServiceLayout";
import ServiceTitle from "../../components/ServiceTitle";
import { OfferingsSection, OfferingTextContent } from '../../components/offerings';
import "../../styles.css";

export default props => {
  let titleComponent = (
    <ServiceTitle
      title='Marketing Photography'
      subtitle="Colorful or muted, playful or staid, let images capture your viewer's attention. We offer professional in-house photography and photography editing services. Take a look below to see some of our recent work!"
    />
  );

  const faqSections = [{
    header: "Showcase your business",
    subtitle: "Professional quality photos of your business customers will want to see with a visit.",
    detail: ""
  }, {
    header: "Own your photos",
    subtitle: "You'll get a copy of any photos we take so you can use them any time in the future as much as you like."
  }];

  const demoPhotos = props.data.allCloudinaryMedia.nodes
    .filter(
      d =>
        !d.tags.includes("photoshop") &&
        !d.secure_url.includes("charles-w-morgans") &&
        !d.secure_url.includes("top_3.jpg") &&
        !d.secure_url.includes("top_1.png") &&
        !d.secure_url.includes("/13")
    )
    .reverse();

  const photoshopExamples = props.data.allCloudinaryMedia.nodes.filter(d =>
    d.tags.includes("photoshop")
  );

  const beforePhotoshopExample = photoshopExamples.filter(p => p.tags.includes("before"))[0];
  const afterPhotoshopExample = photoshopExamples.filter(p => p.tags.includes("after"))[0];

  return (
    <ServiceLayout titleComponent={titleComponent} wipWiderLayout
      helmetTitle="Marketing Photography Services, Professional Photoshop, Cleveland, Westlake, OH | Red Brick Road Studio"
    >
      <div className='flex flex-col md:flex-row md:flex-wrap justify-center'>
        {demoPhotos.slice(0, 2).map((d, index) => {
          const faqSection = faqSections[index];
          return (
            <div className="my-4" key={index}>
              <OfferingsSection
                flipOrder={index % 2}
                header={faqSection.header}
                subtitle={faqSection.subtitle}
                detail={faqSection.detail}
                navActionUrl={d.secure_url}
                navAction=""
                imgSrc={d.secure_url}
                exampleDescription=""
              />
            </div>);
        })}

        <div className='text-gray-800 rounded p-6 mt-6'>
          <h2 className='font-semibold text-lg lg:text-2xl mt-4 lg:mt-8 mb-2'>
            Need your photos to have that finishing touch?
        </h2>
          <div className='text-sm mt-2 mb-4'>
            <p className='text-lg font-semibold text-gray-700'>We can help.</p>
            <div className='mt-8 text-lg'>
              <p>
                A local school wanted a photo showcasing a Mural that had recently been finished on
                their building, but other equipment in the photo was in the way.
            </p>
            </div>
          </div>

          <div>
            <div className='my-6'>
              <p className='font-semibold text-gray-800 lg:text-lg my-4'>Before:</p>
              <a href={beforePhotoshopExample.secure_url} rel='noopener noreferrer' target='_blank'>
                <Img
                  imageName={beforePhotoshopExample.public_id}
                  cloudName='frannsoft'
                  fluid={{ maxWidth: 700, maxHeight: 700 }}
                  className='rounded shadow-md'
                  imgStyle={{ objectFit: `contain` }}
                  style={{
                    borderRadius: `0.25rem`
                  }}
                />
              </a>
            </div>

            <div className="py-6">
              <p className='font-semibold text-gray-800 lg:text-lg my-4'>After our editing:</p>
              <a href={afterPhotoshopExample.secure_url} rel='noopener noreferrer' target='_blank'>
                <Img
                  imageName={afterPhotoshopExample.public_id}
                  cloudName='frannsoft'
                  fluid={{ maxWidth: 700, maxHeight: 700 }}
                  className='rounded shadow-md'
                  imgStyle={{ objectFit: `contain` }}
                  style={{
                    borderRadius: `0.25rem`
                  }}
                />
              </a>
            </div>
          </div>
        </div>

        <div className="px-8 text-center pt-10 max-w-3xl mx-auto">
          <OfferingTextContent
            header="More examples of our work"
            subtitle=""
            detail=""
          />
        </div>

        <div className="w-full flex flex-wrap justify-center">
          {demoPhotos.map((d, i) => {
            return (
              <div key={i} className='w-full md:w-1/4 mb-2 md:m-2 rounded shadow-md'>
                <a target='_blank' href={d.secure_url} rel='noopener noreferrer'>
                  <Img
                    imageName={d.public_id}
                    cloudName='frannsoft'
                    fluid={{ maxWidth: 300, height: 300 }}
                    className='rounded shadow-md'
                    style={{
                      borderRadius: `0.25rem`
                    }}
                  />
                </a>
              </div>
            );
          })}
        </div>

      </div>


    </ServiceLayout>
  );
};

export const query = graphql`
  query {
    allCloudinaryMedia(filter: { url: { regex: "*/(photography)/" } }) {
      nodes {
        public_id
        secure_url
        tags
      }
    }
  }
`;
