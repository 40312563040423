import React from "react"
import { ContactUsButton } from "./ContactUsButton"

export const ContactUsSection = props => {
  return (
    <div className='flex-col justify-center items-center text-center'>
      <p className='font-medium text-gray-800 text-xl md:text-2xl mb-2 md:mb-2'>We would love to help you grow your business!</p>
      <div className='mt-4 sm:mt-8'>
        <ContactUsButton actionText='Contact us to get started' compact />
      </div>
    </div>
  )
}
