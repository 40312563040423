import React from "react"
import { Arrow } from "../symbols/Arrow"

export const SectionNavAction = props => {
  return (
    <div className='flex items-center py-1 bg-transparent font-semibold mt-2 sm:text-md md:text-lg lg:text-xl hover:text-primary'>
      {props.children}
      <Arrow />
    </div>
  )
}
