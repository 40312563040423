import React from "react"

export const SectionContainer = props => {
  const { className } = props
  return (
    <section
      className={`flex flex-col md:flex-row my-2 sm:my-6 mx-4 sm:mx-8 sm:mx-auto sm:h-screen-1/4 text-gray-800 ${className}`}>
      {props.children}
    </section>
  )
}
